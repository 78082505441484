<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="processTable2"
          title="단위공정 목록"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          @rowClick="rowClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <q-form ref="editForm">
          <c-card title="단위공정 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && this.parentProcess !== null" label="공정설명서(공정흐름도)" icon="download" @btnClicked="downloadFlow" />
                <c-btn v-if="editable && this.parentProcess !== null" label="신규 단위공정" icon="add" @btnClicked="addChild" />
                      <c-btn
                  v-if="editable"
                  :disabled="!saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!-- 단위공정명 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="단위공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && dataeditable && !updateMode"
                  :required="true"
                  label="단위공정코드"
                  name="processCd"
                  v-model="data.processCd">
                </c-text>
              </div>
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :comboItems="comboItems"
                  itemText="codeName"
                  itemValue="code"
                  label="상위공정정보 동일"
                  name="col1"
                  v-model="data.col1">
                </c-checkbox>
              </div> -->
              <div class="col-3">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="순번"
                  name="orderNo"
                  v-model="data.orderNo">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 관리부서 -->
                <c-dept-multi label="관리부서" :editable="editable && dataeditable" :parentCheckDepts="data.managementDepts" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-3">
                <!-- 사용여부 -->
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-9">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="단위공정 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-select
                  :editable="editable && dataeditable"
                  codeGroupCd="WORK_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workCycleCd"
                  label="작업발생 주기"
                  v-model="data.workCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && dataeditable"
                  type="number"
                  label="작업시간(1회)"
                  name="workingTime"
                  v-model="data.workingTime">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable && dataeditable"
                  label="사용온도"
                  name="temperature"
                  suffix="°C"
                  v-model="data.temperature">
                </c-text>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfo1"
                  :editable="editable && attachable && dataeditable"
                  label="단위공정 사진">
                </c-upload>
              </div>
              <!-- <div class="col-12">
                <c-upload
                  :attachInfo="attachInfo2"
                  :editable="editable && attachable"
                  label="공정설명서(공정흐름도)">
                </c-upload>
              </div> -->
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  props: {
    parentProcess: {
      type: Object
    },
  },
  data() {
    return {
      attachable: false,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_PHOTO',
        taskKey: '',
      },
      // attachInfo2: {
      //   isSubmit: '',
      //   taskClassCd: 'PROCESS_UNIT_FLOW',
      //   taskKey: '',
      // },
      useFlagItems: [],
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        col1: []
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      lvl1Check: false,
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentProcess() {
      if (this.parentProcess !== null) {
        this.getProcessList({ processCd: this.parentProcess.processCd })
        this.$set(this.attachInfo1, 'taskKey', '');
        // this.$set(this.attachInfo2, 'taskKey', '');
      } else {
        this.reset();
        this.rowNotSelected = true;
        this.grid.data = [];
        this.$set(this.attachInfo1, 'taskKey', '');
        // this.$set(this.attachInfo2, 'taskKey', '');
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
      if (this.parentProcess !== null) {
        this.getProcessList({ processCd: this.parentProcess.processCd });
      }
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getProcessList(row) {
      if (row.processCd !== undefined) {
        this.getList(row);
        this.reset();
      } else {
        this.reset();
        this.rowNotSelected = true;
        this.grid.data = [];
      }
    },
    getList(row) {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processLevelCd: '20',
        upProcessCd: row.processCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
      this.attachable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.processCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.attachable = true;
        this.dataeditable = true;
        this.$set(this.attachInfo1, 'taskKey', this.selectedProcessCd);
        // this.$set(this.attachInfo2, 'taskKey', this.selectedProcessCd);
      },
      () => {
      });
    },
    addChild() {
      this.saveable = true;
      this.deleteable = false;
      this.lvl1Check = false;
      this.lvl2Check = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.attachable = true;
      this.dataeditable = true;
      this.data = {
        plantCd: this.parentProcess.plantCd,
        processCd: '',
        processName: '',
        upProcessCd: this.parentProcess.processCd,
        processLevelCd: '20',
        processDesc: '',
        managementDepts: this.parentProcess.managementDepts,
        orderNo: '',
        useFlag: 'Y',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        psmFlag: this.parentProcess.psmFlag,
        col1: []
      };
      this.rowRemoveSelect();
      this.$set(this.attachInfo1, 'taskKey', '');
      // this.$set(this.attachInfo2, 'taskKey', '');
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.attachable = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        temperature: '',
        useFlag: 'Y',
        workCycleCd: null,
        workingTime: null,
        psmFlag: 'Y',
        col1: []
      };
    },
    addrow() {
    },
    removeRow() {
    },
    downloadFlow() {

    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 시퀀스 아이디가 존재합니다.', // 동일한 시퀀스 아이디가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getProcessList({ processCd: this.parentProcess.processCd })
      } else {
        this.$set(this.attachInfo1, 'taskKey', result.data.processCd)
        this.$set(this.attachInfo1, 'isSubmit', uid())
        // this.$set(this.attachInfo2, 'taskKey', result.data.processCd)
        // this.$set(this.attachInfo2, 'isSubmit', uid())
        this.getProcessList({ processCd: this.parentProcess.processCd })
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getProcessList({ processCd: this.parentProcess.processCd })
      this.selectedProcessCd = '';
    },
  }
};
</script>
